<template>
  <app-container class="privacy-policy">
    <h1>Política de Privacidade</h1>

    <p>
      Esta política descreve as informações que processamos para suportar o
      website InstFutura e nossos widgets.
    </p>

    <h2>Política de Cookies</h2>

    <p>
      Cookies são pequenos arquivos de texto enviados pelo site que você visita
      e salvos em seu dispositivo, como computador, laptop, smartphone, tablet,
      etc. Referimo-nos a todas as outras tecnologias como "cookies" usados para
      fins semelhantes, como dados que armazenamos em seu navegador da web.
    </p>

    <p>
      Quase todos os sites usam cookies atualmente. O nosso site não é uma
      exceção.
    </p>

    <p>
      Se você bloquear ou rejeitar nossos cookies, não poderá usar nossos
      produtos e serviços.
    </p>

    <h2>Que tipo de informação recolhemos e porquê?</h2>

    <p>
      Para fornecer nossos serviços com sucesso, devemos processar informações
      sobre você. Existem vários tipos de informações que coletamos. Depende de
      como você usa InstFutura.
    </p>

    <h3>Quando você vê nosso widget ou visita nosso site</h3>

    <p>
      Coletamos apenas informações básicas de registros - o endereço IP do seu
      dispositivo, informações de conexão, como tipo e versão do navegador,
      sistema operacional, plataforma móvel, identificador exclusivo do
      dispositivo e outros identificadores técnicos. A data, hora e URL
      referenciador de sua solicitação. Todos esses dados são coletados
      automaticamente. Este é o procedimento padrão em quase todos os sites. Em
      alguns casos, também usamos cookies. Você pode ler mais sobre os cookies
      que usamos em nossa Política de Cookies. Todas as informações são
      coletadas principalmente para prevenir abusos, detectar ataques e cumprir
      os regulamentos da lei sobre o armazenamento de tais logs.
    </p>

    <h3>Informações adicionais que coletamos quando você usa nosso site</h3>

    <p>
      Usamos o Google Analytics para entender melhor como nossos usuários
      interagem com nosso site. Usamos esses dados para determinar o número de
      pessoas que usam nosso site, para entender melhor como elas encontram e
      usam nossas páginas da web e para ver sua jornada pelo site. Embora o
      Google Analytics registre dados como seu endereço IP (usamos anonimato de
      endereço IP), localização geográfica, dispositivo, navegador de internet e
      sistema operacional, nenhuma dessas informações o identifica pessoalmente
      para nós.
    </p>

    <h3>
      Dados do Instagram que coletamos quando você faz login no InstFutura
    </h3>

    <p>
      Usamos a API do Instagram para obter informações sobre sua conta. Quando
      você entrar em nosso site pela primeira vez, o Instagram perguntará se
      você deseja nos dar permissão para ler informações básicas sobre sua
      conta. Sem este consentimento do lado do Instagram, não lemos nenhuma
      informação da API do Instagram. Quando você nos concede acesso para ler
      informações básicas, armazenamos alguns desses dados em nosso banco de
      dados. Nós armazenamos:
    </p>

    <ul>
      <li>Seu ID de usuário do Instagram</li>
      <li>Seu nome de usuário do Instagram</li>
      <li>
        Seu nome completo (se você forneceu algum em seu perfil do Instagram).
      </li>
      <li>
        Link para sua foto de perfil (se você forneceu alguma em seu perfil do
        Instagram). Não armazenamos nenhuma imagem em nossos servidores.
      </li>
      <li>Token de acesso ao Instagram</li>
      <li>Usamos esses dados para criar uma conta em nosso site.</li>
      <li>
        Com essa conta, você pode facilmente criar, editar, atualizar e remover
        widgets.
      </li>
      <li>
        Não armazenamos seu endereço de e-mail nem senha de sua conta do
        Instagram.
      </li>
    </ul>

    <h3>
      Dados adicionais do Instagram que coletamos quando você cria uma
      visualização de widget ou widget usando nosso formulário
    </h3>

    <p>
      Quando você tenta criar um widget em nosso site, usamos a API do Instagram
      para obter informações sobre suas fotos e vídeos, como:
    </p>

    <ul>
      <li>Link para postagem do Instagram</li>
      <li>
        Links para imagens ou miniaturas de vídeo. Observe que não armazenamos
        nenhuma imagem ou vídeo em nossos servidores. Tudo é armazenado nos
        servidores do Instagram. Nós apenas usamos links públicos para este
        conteúdo.
      </li>
      <li>Tag</li>
      <li>Rubrica</li>
      <li>Número de comentários e curtidas</li>
    </ul>

    <p>
      Usamos cache temporário para evitar o envio de muitas solicitações para a
      plataforma Instagram. Este cache expira automaticamente em 24 horas.
    </p>
    <p>
      Essas informações são necessárias para criar um widget que você possa
      incorporar ao seu site.
    </p>

    <h2>Compartilhando dados</h2>

    <p>
      Quando você cria uma conta em nosso site e a autoriza no Instagram,
      qualquer pessoa que conheça seu nome de usuário do Instagram pode criar um
      widget para você. Nós permitimos isso porque muitos de nossos clientes
      contratam desenvolvedores web para construir sites para eles. Às vezes,
      nossos usuários não sabem como criar e incorporar widgets em seus sites
      devido à falta de conhecimento sobre o assunto. A prática comum é que o
      desenvolvedor envie um link do nosso site e peça a um cliente apenas para
      efetuar login no InstFutura. Precisamos do consentimento e autorização do
      cliente antes de obtermos as informações da API do Instagram. Uma vez
      feito isso, um desenvolvedor pode criar um widget para uma determinada
      conta.
    </p>

    <p>
      Exceto quando mencionado na seção 2 desta política - não compartilhamos,
      vendemos, transmitimos ou divulgamos dados pessoais. Queremos ser justos
      com nossos usuários. Fotos e vídeos postados no Instagram e exibidos em
      nossos widgets são propriedade deles, não nossa.
    </p>

    <h2>Retenção de dados e exclusão de conta</h2>
    <p>
      Armazenamos os dados até que não sejam mais necessários para fornecer
      nossos serviços ou até que sua conta seja excluída. A qualquer momento
      você pode excluir seus widgets usando a página Meus widgets. Você também
      pode excluir sua conta usando a página Minha conta. Os widgets são
      excluídos permanentemente de nosso banco de dados e de nossos servidores.
      Quando você exclui sua conta, nós excluímos todas as coisas conectadas à
      sua conta, como informações obtidas por meio da API do Instagram e seus
      widgets.
    </p>

    <h2>Mudanças em nossa Política de Privacidade</h2>
    <p>
      Esta política de privacidade pode mudar de tempos em tempos em linha com a
      legislação ou desenvolvimento do site. Não informaremos explicitamente
      nossos clientes ou usuários do site sobre essas mudanças. Em vez disso,
      recomendamos que você verifique esta página ocasionalmente para quaisquer
      alterações de política.
    </p>
  </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer'

export default {
  name: 'PrivacyPolicy',
  components: { AppContainer }
}
</script>

<style lang="sass" scoped>
h1,
h2,
h3,
p,
ul
  margin-bottom: 24px
ul
  margin-left: 24px
li
  margin: 4px 0
</style>
